import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PikAuthService } from 'pik-header';

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
  constructor(private authService: PikAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(':apiUrl:')) {
      return next.handle(req);
    }

    const authReq = req.clone({
      headers: req.headers.set('Authorization', this.authService.getAuthorizationHeaderValue())
    });

    return next.handle(authReq);
  }
}
