import { ModuleWithProviders, NgModule } from '@angular/core';
import { NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER } from './gtag.factory';
import { NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN } from './gtag.tokens';
import { GASettings } from './gtag.types';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [],
})
export class GTagModule {
  static forRoot(settings: GASettings): ModuleWithProviders<GTagModule> {
    return {
      ngModule: GTagModule,
      providers: [
        {
          provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
          useValue: settings,
        },
        NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
      ],
    };
  }
}
