import { Injectable, Inject, isDevMode } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN,
} from './gtag.tokens';
import { GASettings, GtagFn } from './gtag.types';

enum GaActionEnum {
  LOGIN = 'login',
  SEARCH = 'search',
  SELECT_CONTENT = 'select_content',
  SHARE = 'share',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_SEARCH_RESULT = 'view_search_results',
  VIEW_SEARCH_RESULTS = 'view_search_results',
}

@Injectable({
  providedIn: 'root',
})
export class GTagService {
  private get document(): Document {
    return this.documentCtx;
  }

  constructor(
    @Inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN)
    private readonly settings: GASettings,
    @Inject(DOCUMENT) private readonly documentCtx: any,
    @Inject(NGX_GTAG_FN) private readonly gtagFn: GtagFn
  ) {}

  private throw(err: Error) {
    if (
      (this.settings.enableTracing || isDevMode()) &&
      console &&
      console.error
    ) {
      console.error(err);
    }
  }

  private toKeyValue(map: Map<string, any>): { [param: string]: any } | void {
    return map.size > 0
      ? Array.from(map).reduce(
          (obj, [key, value]) =>
            Object.defineProperty(obj, key, { value, enumerable: true }),
          {}
        )
      : undefined;
  }

  gtag(...args: any[]) {
    try {
      this.gtagFn(...args.filter((x) => x !== undefined));
    } catch (err) {
      this.throw(err);
    }
  }

  event(
    action: GaActionEnum | string,
    category?: string,
    label?: string,
    value?: number,
    interaction?: boolean
  ) {
    try {
      const opt = new Map<string, any>();
      if (category) {
        opt.set('event_category', category);
      }
      if (label) {
        opt.set('event_label', label);
      }
      if (value) {
        opt.set('value', value);
      }
      if (interaction !== undefined) {
        opt.set('interaction', interaction);
      }
      const params = this.toKeyValue(opt);
      if (params) {
        this.gtag('event', action as string, params);
      } else {
        this.gtag('event', action as string);
      }
    } catch (error) {
      this.throw(error);
    }
  }

  pageView(path: string, title?: string, location?: string, options?: Object) {
    try {
      const opt = new Map<string, any>([['page_path', path]]);
      if (title) {
        opt.set('page_title', title);
      }
      if (location || this.document) {
        opt.set('page_location', location || this.document.location.href);
      }
      if (options) {
        Object.entries(options).map(([key, value]) => opt.set(key, value));
      }
      this.gtag('config', this.settings.trackingCode, this.toKeyValue(opt));
    } catch (error) {
      this.throw(error);
    }
  }

  config(options?: Object): void {
    const opt = new Map<string, any>();
    if (options) {
      Object.entries(options).map(([key, value]) => opt.set(key, value));
    }
    this.gtag('config', this.settings.trackingCode, this.toKeyValue(opt));
  }

  set(...options: any[]) {
    try {
      this.gtagFn('set', ...options);
    } catch (err) {
      this.throw(err);
    }
  }

  exception(description?: string, fatal?: boolean) {
    try {
      const opt = new Map<string, any>();
      if (description) {
        opt.set('description', description);
      }
      if (fatal) {
        opt.set('fatal', fatal);
      }
      const params = this.toKeyValue(opt);
      if (params) {
        this.gtag('event', 'exception', this.toKeyValue(opt));
      } else {
        this.gtag('event', 'exception');
      }
    } catch (error) {
      this.throw(error);
    }
  }
}
