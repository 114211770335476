import { NgModule } from '@angular/core';
import ru from '@angular/common/locales/ru';
import {
  CommonModule,
  registerLocaleData,
  APP_BASE_HREF,
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  PikAuthModule,
  PikHeaderModule,
  PIK_AUTH_SETTINGS,
  PikAuthSettings,
  PikAuthService,
} from 'pik-header';
import { AuthModule } from 'pik-angular-auth-oidc-client';
import { environment } from '@env/environment';
import { ApplyTokenInterceptor } from './interceptors/apply-token.interceptor';
import { ApiResolveInterceptor } from './interceptors/api-resolve.interceptor';
import { PdConfigService } from './config.service';
import { GTagModule } from './gtag/gtag.module';
import { NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN } from './gtag/gtag.tokens';
import { GASettings } from './gtag/gtag.types';

registerLocaleData(ru);

export function initAuthFactory(configService: PdConfigService) {
  configService.config = (window as any).ENV_CONFIG;
  const { auth, needAuth } = configService.config || environment;
  const authSettings: PikAuthSettings = {
    needAuth,
    client_id: auth.client_id,
    scope: auth.scope,
    authEnvironment: auth.authEnvironment,
    allowExternal: auth.allowExternal,
  };
  return authSettings;
}

function initGAFactory(configService: PdConfigService): GASettings {
  configService.config = (window as any).ENV_CONFIG;
  const { GAID } = configService.config || environment;
  return {
    trackingCode: GAID,
  };
}

@NgModule({
  exports: [PikHeaderModule],
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    PikHeaderModule.forRoot(),
    AuthModule.forRoot(),
    PikAuthModule.forRoot(),
    GTagModule.forRoot({ trackingCode: '' }),
  ],
  providers: [
    PikAuthService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      multi: false,
      provide: PIK_AUTH_SETTINGS,
      useFactory: initAuthFactory,
      deps: [PdConfigService],
    },
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useFactory: initGAFactory,
      deps: [PdConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplyTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResolveInterceptor,
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
})
export class CoreModule {}
