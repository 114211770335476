import { Provider, APP_INITIALIZER, isDevMode } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN,
} from './gtag.tokens';
import { GASettings, GtagFn, GACommand } from './gtag.types';

/**
 * Provide a DI Configuration to attach GA Initialization at Angular Startup Cycle.
 */
export const NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: gaInitializer,
  deps: [NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN, DOCUMENT],
};

/**
 * Create a script element on DOM and link it to Google Analytics tracking code URI.
 * After that, execute exactly same init process as tracking snippet code.
 */
export function gaInitializer(
  settings: GASettings,
  gtag: GtagFn,
  document: Document
) {
  return async () => {
    if (!settings.trackingCode) {
      if (!isDevMode()) {
        console.error(
          'Не указан идентификатор отслеживания для Google Analytics.'
        );
      }

      return;
    }

    if (!gtag) {
      if (!isDevMode()) {
        console.error('Не удалось найти метод gtag().');
      }

      return;
    }

    if (!document) {
      if (!isDevMode()) {
        console.error('Не удалось получить доступ к Document.');
      }
    }

    settings.uri =
      settings.uri ||
      `https://www.googletagmanager.com/gtag/js?id=${settings.trackingCode}`;

    const initialCommands: GACommand[] = [
      { command: 'js', values: [new Date()] },
      { command: 'config', values: [settings.trackingCode] },
    ];

    settings.initCommands = [
      ...initialCommands,
      ...(settings.initCommands || []),
    ];

    for (const command of settings.initCommands) {
      gtag(command.command, ...command.values);
    }

    const s: HTMLScriptElement = document.createElement('script');
    s.async = true;
    s.src = settings.uri;

    if (settings.nonce) {
      s.setAttribute('nonce', settings.nonce);
    }

    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.appendChild(s);
  };
}
