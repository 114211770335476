import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PikAuthGuard } from 'pik-header';
import { ErrorPageComponent } from './components/error-page/error-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./registry/registry.module').then(m => m.PdRegistryModule),
    canActivate: [PikAuthGuard]
  },
  {
    path: 'pd-item',
    loadChildren: () => import('./project-declaration/project-declaration.module').then(m => m.ProjectDeclarationModule),
    canActivate: [PikAuthGuard]
  }
  // {
  //   path: '**',
  //   component: ErrorPageComponent,
  //   canActivate: [PikAuthGuard],
  //   data: {
  //     code: 404,
  //     title: 'Страница не найдена',
  //     message: ['Эта страница либо потерялась, либо находится в разработке']
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
