import { GTagService } from './core/gtag/gtag.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmployeeService, PikAuthService } from 'pik-header';
import { skip, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public authService: PikAuthService,
    public employeeService: EmployeeService,
    private router: Router,
    private gtagService: GTagService
  ) {
    this.authService.authCallbackIfRequired();
    this.employeeService.getCurrent().subscribe((user) => {
      this.gtagService.config({ user_id: user.login || user.email });
    });
  }

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.destroyed$), skip(1))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.gtagService.pageView(event.urlAfterRedirects, undefined);
        }
      });
  }

  ngOnDestroy(): void {
    this.authService.unsubscribeModuleSetup();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
