import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PdConfigService } from './../config.service';

@Injectable()
export class ApiResolveInterceptor implements HttpInterceptor {
  constructor(
    private config$: PdConfigService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const resolvedReq = req.clone({
      url: this.resolveApi(req.url)
    });

    return next.handle(resolvedReq);
  }

  private resolveApi(url: string): string {
    if (url.includes(':apiUrl:')) {
      url = url.replace(':apiUrl:', this.config$.config.pdApiUrl);
    }
    return url;
  }
}
