import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

interface PdConfig {
  production: boolean;
  pdApiUrl: string;
  needAuth: boolean;
  auth: {
    client_id: string;
    scope: string;
    allowExternal: boolean;
    authEnvironment: number;
    // test = 1,
    // stageTest = 2,
    // stageProd = 3,
    // prod = 4,
    // custom = 5
  };
  GAID: string;
}

@Injectable({
  providedIn: 'root'
})
export class PdConfigService {
  private configValue: PdConfig;

  get config(): PdConfig {
    return this.configValue;
  }

  set config(value) {
    this.configValue = value ? value : environment;
  }
}
