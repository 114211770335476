import { isPlatformBrowser } from '@angular/common';
import { InjectionToken, inject, PLATFORM_ID } from '@angular/core';
import { GtagFn, GASettings, DataLayer } from './gtag.types';

export function getGtagFn(window: Window, dataLayer: DataLayer): GtagFn {
  return window
    ? (window['gtag'] =
        window['gtag'] ||
        function () {
          dataLayer.push(arguments as any);
        })
    : null;
}

/**
 * Provides an injection token to access Google Analytics Gtag Function
 */
export const NGX_GTAG_FN = new InjectionToken<GtagFn>('ngx-gtag-fn', {
  providedIn: 'root',
  factory: () => getGtagFn(inject(NGX_WINDOW), inject(NGX_DATA_LAYER)),
});

/**
 * Provide a Injection Token to global settings.
 */
export const NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN = new InjectionToken<
  GASettings
>('ngx-google-analytics-settings', {
  factory: () => ({ trackingCode: '', enableTracing: false }),
});

export function getWindow(platformId: any) {
  return isPlatformBrowser(platformId) ? window : null;
}

/**
 * Provide DOM Window reference or null if the environment is not a Browser.
 */
export const NGX_WINDOW = new InjectionToken<Window>('ngx-window', {
  providedIn: 'root',
  factory: () => getWindow(inject(PLATFORM_ID)),
});

export function getDataLayerFn(window: Window): DataLayer {
  return window ? (window['dataLayer'] = window['dataLayer'] || []) : null;
}

/**
 * Provides an injection token to access Google Analytics DataLayer Collection
 */
export const NGX_DATA_LAYER = new InjectionToken<DataLayer>('ngx-data-layer', {
  providedIn: 'root',
  factory: () => getDataLayerFn(inject(NGX_WINDOW)),
});
