export const environment = {
  production: false,
  pdApiUrl: 'https://api.test.pd.pik-digital.ru',
  needAuth: true,
  auth: {
    client_id: 'pdl_spa',
    scope: 'openid profile email offline_access pdl_api home_api navigation_api wiki_api',
    authEnvironment: 1,
    allowExternal: true
  },
  GAID: ''
};
